const baseURL = process.env.REACT_APP_API_URL;

export async function getActivePlayersSorted() {
  try {
    const response = await fetch(`${baseURL}/api/players/active/sorted`);
  
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  
    const text = await response.text();
  
    try {
      const data = JSON.parse(text); // Try to parse the text as JSON
      return data; // Return the parsed data
    } catch (jsonError) {
      console.error('Error parsing JSON:', jsonError);
      return [];
    }
  } catch (error) {
    console.error('Error fetching player info:', error);
    return [];
  }
}


export async function getPlayer(id) {
    try {
      const response = await fetch(`${baseURL}/api/playerinfo/${id}`);
    
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    
      const text = await response.text();
    
      try {
        const data = JSON.parse(text); // Try to parse the text as JSON
        return data; // Return the parsed data
      } catch (jsonError) {
        console.error('Error parsing JSON:', jsonError);
        return [];
      }
    } catch (error) {
      console.error('Error fetching player info:', error);
      return [];
    }
}



export async function getPlayersByTeam(team) {
    team = team.toUpperCase();
    try {
      const response = await fetch(`${baseURL}/api/players/${team}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    
      const text = await response.text();

    
      try {
        const data = JSON.parse(text); // Try to parse the text as JSON
        return data; // Return the parsed data
      } catch (jsonError) {
        console.error('Error parsing JSON:', jsonError);
        return [];
      }
    } catch (error) {
      console.error('Error fetching player info:', error);
      return [];
    }
}