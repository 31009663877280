import React from 'react';
import { Menu, Layout, ConfigProvider } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import './../css/headercomponent.css'; // Import your CSS file

const { Header } = Layout;

const HeaderComponent = () => {
  const location = useLocation();

  return (
    <Header className='header'>
      <div className='centered-header'>
        <div className="logo-container">
          <img src="/images/dynastyimpng.webp" alt="Logo" className="logo" />
        </div>
        <div className="menu-container">
          <ConfigProvider
            theme={{
              components: {
                Menu: {
                  itemColor: 'transparent', // Change background color here
                  horizontalItemSelectedColor: '#ffffff', // Optional: Change text color
                  itemHoverColor: '#ffffff'
                },
              },
            }}
          >
            <Menu
              mode="horizontal"
              selectedKeys={[location.pathname]}
              className="custom-menu"
            >
              <Menu.Item key="/"><Link to="/">HOME</Link></Menu.Item>
              <Menu.Item key="/players"><Link to="/players">PLAYERS</Link></Menu.Item>
              <Menu.Item key="/rostership"><Link to="/rostership">ROSTERSHIP</Link></Menu.Item>
              <Menu.Item key="/feedback"><Link to="/feedback">FEEDBACK</Link></Menu.Item>
            </Menu>
          </ConfigProvider>
        </div>
      </div>
    </Header>
  );
};

export default HeaderComponent;
