// src/pages/RostershipPage.js

import './../css/rostershippage.css';
import { useState } from 'react';
import { Button, Input, message } from "antd";
import { downloadExcel, checkUsernameExists } from './../services/rostershipservice';
import React from 'react';

function RostershipPage() {
  const [username, setUsername] = useState('');
  
  const handleVerifyUsername = async () => {
    try {
      const response = await checkUsernameExists(username);
      if (response === null) {
        message.error('User does not exist.');
      } else {
        let didDownload = downloadExcel(username);
        if (didDownload) {
          message.success('Excel Downloading...');
        }
      }
    } catch (error) {
      console.error('Error verifying username:', error);
      message.error('Failed to verify username. Please try again.');
    }
  };

  const handleInputChange = (e) => {
    setUsername(e.target.value);
  };

  return (
    <div className="rostershippage">
      <h1>Rostership</h1>
      <i>View your dynasty rostership and team details.</i>
      <p>Enter your sleeper username below:</p>
      <div className='center'>
        <Input
          value={username}
          onChange={handleInputChange}
          style={{ width: 200 }}
        />
      </div>
      <div >
      <Button onClick={handleVerifyUsername}>Download</Button>

      </div>
    </div>
  );
}

export default RostershipPage;
