const baseURL = process.env.REACT_APP_API_URL;


export async function downloadExcel(username) {
    try {
        const response = await fetch(`${baseURL}/api/sync_rosters/${username}`);  // Replace with your endpoint URL
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const blob = await response.blob();  // Get the blob object from the response

        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([blob]));

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'sleeper_sync.xlsx');  // Set the download attribute with desired file name
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up resources
        link.parentNode.removeChild(link);
        return true;
    } catch (error) {
        console.error('Error downloading Excel:', error);
    }
}


const SLEEPER_API_BASE_URL = 'https://api.sleeper.app/v1';

// Function to check if a Sleeper username exists
export const checkUsernameExists = async (username) => {
  try {
    const response = await fetch(`${SLEEPER_API_BASE_URL}/user/${username}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (data === null) {
      return null; // User does not exist
    }
    return data; // Return user data if exists
  } catch (error) {
    console.error('Error checking username:', error);
    throw error;
  }
};