export const qb = [
    {
      title: 'PPG',
      dataIndex: 'ppg',
      key: 'ppg',
      width: 50,
      sorter: (a, b) => a.ppg - b.ppg,
    },
    {
      title: 'PPPG',
      dataIndex: 'pppg',
      key: 'pppg',
      width: 50,
      sorter: (a, b) => a.pppg - b.pppg,
    },
    {
      title: 'RPPG',
      dataIndex: 'rppg',
      key: 'rppg',
      width: 50,
      sorter: (a, b) => a.rppg - b.rppg,
    },
    {
      title: 'aY/A',
      dataIndex: 'aya',
      key: 'aya',
      width: 40,
      sorter: (a, b) => a.aya - b.aya,
    },
    {
      title: 'PASSTD%',
      dataIndex: 'passtdpercent',
      key: 'passtdpercent',
      width: 70,
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.passtdpercent - b.passtdpercent,
    },
    {
      title: 'YPA',
      dataIndex: 'ypa',
      key: 'ypa',
      width: 40,
      sorter: (a, b) => a.ypa - b.ypa,
    },
    {
      title: 'CARRY%',
      dataIndex: 'carrypercent',
      key: 'carrypercent',
      width: 60,
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.carrypercent - b.carrypercent,
    },
    {
      title: 'YPTRA',
      dataIndex: 'yptra',
      key: 'yptra',
      width: 60,
      sorter: (a, b) => a.yptra - b.yptra,
    },
    {
      title: 'RUSHTD%',
      dataIndex: 'rushtdpercent',
      key: 'rushtdpercent',
      width: 70,
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.rushtdpercent - b.rushtdpercent,
    },
  ];


  export const rb = [
    {
      title: 'PPG',
      dataIndex: 'ppg',
      key: 'ppg',
      width: 60,
      sorter: (a, b) => a.ppg - b.ppg,
    },
    {
      title: 'CARRY%',
      dataIndex: 'carrypercent',
      key: 'carrypercent',
      width: 100,
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.carrypercent - b.carrypercent,
    },
    {
      title: 'YPTRA',
      dataIndex: 'yptra',
      key: 'yptra',
      width: 80,
      sorter: (a, b) => a.yptra - b.yptra,
    },
    {
      title: 'RUSH TD MS',
      dataIndex: 'rushtdms',
      key: 'rushtdms',
      width: 150,
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.rushtdms - b.rushtdms,
    },
    {
      title: 'TARGET SHARE',
      dataIndex: 'targetshare',
      key: 'targetshare',
      width: 150,
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.ts - b.ts,
    },
    {
      title: 'YPTPA',
      dataIndex: 'yptpa',
      key: 'yptpa',
      width: 80,
      sorter: (a, b) => a.yptpa - b.yptpa,
    },
    
  ];


  export const wr = [
    {
      title: 'PPG',
      dataIndex: 'ppg',
      key: 'ppg',
      width: 60,
      sorter: (a, b) => a.ppg - b.ppg,
    },
    {
      title: 'TARGET SHARE',
      dataIndex: 'targetshare',
      key: 'targetshare',
      width: 150,
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.ts - b.ts,
    },
    {
      title: 'YPTPA',
      dataIndex: 'yptpa',
      key: 'yptpa',
      width: 80,
      sorter: (a, b) => a.yptpa - b.yptpa,
    },
    {
      title: 'TARGET RATE',
      dataIndex: 'targetrate',
      key: 'targetrate',
      render: (text) => `${text}%`, // Append percentage sign
      width: 150,
      sorter: (a, b) => a.targetrate - b.targetrate,
    },
    {
      title: 'YPRR',
      dataIndex: 'yprr',
      key: 'yprr',
      width: 150,
      sorter: (a, b) => a.yppr - b.yppr,
    },
  ]

  export const te = [
    {
      title: 'PPG',
      dataIndex: 'ppg',
      key: 'ppg',
      width: 60,
      sorter: (a, b) => a.ppg - b.ppg,
    },
    {
      title: 'TARGET SHARE',
      dataIndex: 'targetshare',
      key: 'targetshare',
      width: 150,
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.ts - b.ts,
    },
    {
      title: 'YPTPA',
      dataIndex: 'yptpa',
      key: 'yptpa',
      width: 80,
      sorter: (a, b) => a.yptpa - b.yptpa,
    },
    {
      title: 'TARGET RATE',
      dataIndex: 'targetrate',
      key: 'targetrate',
      render: (text) => `${text}%`, // Append percentage sign
      width: 150,
      sorter: (a, b) => a.targetrate - b.targetrate,
    },
    {
      title: 'YRPR',
      dataIndex: 'yprr',
      key: 'yprr',
      width: 150,
      sorter: (a, b) => a.yppr - b.yppr,
    },
  ]
  