import React from 'react';
import PlayerCard from '../views/playercard';
import PlayerTable from '../views/playertable';
import './../css/playerspage.css';

function PlayersPage({ players, handleRowClick, selectedPlayer, setSelectedPlayer }) {
    return (
        <div className="playerspage">
            <div className='center2'>
                Click on a player to see more information
            </div>
            <div className={`player-card-container ${selectedPlayer ? '' : 'hidden'}`}>
                {selectedPlayer && <PlayerCard player={selectedPlayer} onClose={() => setSelectedPlayer(null)} setSelectedPlayer={setSelectedPlayer} />}
            </div>
            <div className="table-container">
                <PlayerTable data={players} onRowClick={handleRowClick} setSelectedPlayer={setSelectedPlayer} />
            </div>
        </div>
    );
}

export default PlayersPage;
