import React from 'react';
import { Table, ConfigProvider } from 'antd';
import { qb, rb, wr, te } from '../utils/tablecolumns'; // Make sure these imports are correct

const qblength = 1000;
const wrlength = 800;
const rblength = 800;
const telength = 800;

const stablecolumns = [
  {
    title: '#',
    dataIndex: 'yearnum',
    key: 'yearnum',
    width: 50,
    sorter: (a, b) => a.yearnum - b.yearnum,
    render: (text) => `Year ${text}`,
  },
  {
    title: 'YR',
    dataIndex: 'calendaryear',
    key: 'calendaryear',
    width: 50,
    sorter: (a, b) => a.calendaryear - b.calendaryear,
  },
  // {
  //   title: 'TM',
  //   dataIndex: 'team',
  //   key: 'team',
  //   width: 50,
  //   sorter: (a, b) => a.team.localeCompare(b.team),
  // },
  // {
  //   title: 'GM',
  //   dataIndex: 'games',
  //   key: 'games',
  //   width: 50,
  //   sorter: (a, b) => a.games - b.games,
  // },
];

const getPositionColumns = (position) => {
  // Convert the position to lowercase to ensure case-insensitive comparison
  const lowerCasePosition = position.toLowerCase();

  switch (lowerCasePosition) {
    case 'qb':
      return qb;
    case 'rb':
      return rb;
    case 'wr':
      return wr;
    case 'te':
      return te;
    default:
      return [];
  }
};

const getColumnLength = (position) => {
  // Convert the position to lowercase to ensure case-insensitive comparison
  const lowerCasePosition = position.toLowerCase();

  switch (lowerCasePosition) {
    case 'qb':
      return qblength;
    case 'rb':
      return rblength;
    case 'wr':
      return wrlength;
    case 'te':
      return telength;
    default:
      return 80;
  }
};

const StatsTable = ({ position, stats }) => {
  const positionColumns = getPositionColumns(position);
  const columns = [...stablecolumns, ...positionColumns];
  const columnlength = getColumnLength(position);

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            bodySortBg: '#696969', // Set your desired background color for sorted columns
          },
        },
      }}
    >
      <Table
        columns={columns}
        dataSource={stats}
        scroll={{ x: 400, y: 180, scrollBar: true }}
        pagination={{
          position: ['none', 'none'],
        }}
        sortDirections={['descend', 'ascend']}
        rowHoverable={false}
        size="small"
        className="custom-table" // Apply custom class for styling
      />
    </ConfigProvider>
  );
};

export default StatsTable;
