import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd'; // Import message from Ant Design for notifications
import './../css/feedback.css'; // Import the CSS file
import { createGitHubIssue } from './../services/feedbackservice.js'; // Import the API function

const FeedbackForm = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const response = await createGitHubIssue(values);

            if (response.error) {
                message.error('Failed to send feedback. Please try again.');
            } else {
                message.success('Feedback sent successfully!');
                // Optionally, reset the form after successful submission
                form.resetFields();
            }
        } catch (error) {
            message.error('Error sending feedback. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='feedbackpage'>
            <div className='center2'>
                Have feedback? Want to request features? Let us know down below
            </div>
            <div className="feedback-container">
                <Form
                    form={form}
                    name="feedback-form"
                    className="feedback-form"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="title"
                        rules={[{ required: true, message: 'Please input your feedback title!' }]}
                    >
                        <Input placeholder="Feedback Title" />
                    </Form.Item>

                    <Form.Item
                        name="body"
                        rules={[{ required: true, message: 'Please input your feedback body!' }]}
                    >
                        <Input.TextArea placeholder="Feedback Body" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Submit Feedback
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default FeedbackForm;
