import React from 'react';
import './../css/playerimage.css'

const nflTeams = {
    'ARI': '/images/ari.png',
    'ATL': '/images/atl.png',
    'BAL': '/images/bal.png',
    'BUF': '/images/buf.png',
    'CAR': '/images/car.png',
    'CHI': '/images/chi.png',
    'CIN': '/images/cin.png',
    'CLE': '/images/cle.png',
    'DAL': '/images/dal.png',
    'DEN': '/images/den.png',
    'DET': '/images/det.png',
    'GB': '/images/gb.png',
    'HOU': '/images/hou.png',
    'IND': '/images/ind.png',
    'JAX': '/images/jac.png',
    'KC': '/images/kc.png',
    'LVR': '/images/lv.png',
    'LAC': '/images/lac.png',
    'LAR': '/images/lar.png',
    'MIA': '/images/mia.png',
    'MIN': '/images/min.png',
    'NE': '/images/ne.png',
    'NO': '/images/no.png',
    'NYG': '/images/nyg.png',
    'NYJ': '/images/nyj.png',
    'PHI': '/images/phi.png',
    'PIT': '/images/pit.png',
    'SF': '/images/sf.png',
    'SEA': '/images/sea.png',
    'TB': '/images/tb.png',
    'TEN': '/images/ten.png',
    'WAS': '/images/was.png',
    'FA': '/images/nfl.png'
};

const PlayerImage = ({ player, player_info }) => {
    const defaultImage = nflTeams[player_info.team] || '/images/default.png';

    return (
        <div className="tall">
            {player.imageUrl ? (
                <div className="image-exists">
                    <img
                        className='player-img'
                        src={player.imageUrl}
                        alt={`${player_info.first_name}'s`}
                    />
                </div>
            ) : (
                <div className="image-missing">
                    <img
                        className='player-img'
                        src={defaultImage}
                        alt={`${player_info.first_name}'s`}
                    />
                </div>
            )}
        </div>
    );
};


export default PlayerImage;
