// api.js

import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

export async function createGitHubIssue(issueData) {
  try {
    const response = await axios.post(`${baseURL}/api/create-issue`, issueData);

    if (response.status === 201) {
      return { success: true, message: 'Issue created successfully!' };
    } else {
      return { success: false, message: 'Failed to create issue' };
    }
  } catch (error) {
    console.error('Error creating issue:', error);
    return { success: false, message: 'Failed to create issue' };
  }
}
